import { ContentSectionType } from '@src/ts/constants';
import { IContentSection } from '@src/ts/interfaces';

import { AdditionalInfoSection } from './AdditionalInfoSection';
import { FAQSection } from './FAQSection';
import { GetStartedSection } from './GetStartedSection';
import { HeroSection } from './HeroSection';
import { LearningSection } from './LearningSection';
import { PartnerSection } from './PartnerSection';

export const ContentSection: React.FC<{
    className?: string;
    section: IContentSection;
    events_ref?: React.RefObject<HTMLDivElement>;
}> = ({ section, className, events_ref }) => {
    switch (section?.name) {
        case ContentSectionType.HeroSection:
            return <HeroSection section={section} events_ref={events_ref} />;
        case ContentSectionType.PartnerSection:
            if (section.images?.length > 0) {
                return (
                    <PartnerSection section={section} className={className} />
                );
            }
            break;
        case ContentSectionType.LearningSection:
            return <LearningSection section={section} className={className} />;
        case ContentSectionType.FAQ:
            return <FAQSection section={section} className={className} />;
        case ContentSectionType.GetStartedSection:
            return (
                <GetStartedSection
                    events_ref={events_ref}
                    section={section}
                    className={className}
                />
            );
        case ContentSectionType.AdditionalInfoSection:
            return (
                <AdditionalInfoSection
                    section={section}
                    className={className}
                />
            );
        default:
            return (
                <div>
                    ContentSection {section?.name || 'UNDEFINED'} not yet
                    implemented!
                </div>
            );
    }
};
