import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationBarProps, UpgradeTierStage } from '@decub8/ui';

import { api_client } from '@src/bootstrap/index';
import { useNotification } from '@src/components/Layout/Header/hooks/useNotification';
import { ModalType } from '@src/components/Project/Event/EventModals';
import { CONTENT } from '@src/config';
import { EU_COUNTRIES } from '@src/constants';
import { createContractManager, IContractManager } from '@src/contracts';
import { CONTENT_API } from '@src/services/content';
import { PROJECT } from '@src/services/project';
import { ContentSectionType, IdentityVerifiedStatus } from '@src/ts/constants';
import { IContentSection, ITier, IUserTier, Project } from '@src/ts/interfaces';
import {
    getEventStatus,
    getHasEURestrictionBool,
    getTierOfUser,
    getTiers,
} from '@src/utils/getters';

import { getAndSetUser } from '../pages/useLogin/utils';
import { useUserStatus } from '../useUserStatus';
import { useAppSelector } from '..';

export interface EventRegistrationContext {
    project_slug: string;
    event_slug: string;
}

export interface EventModalState {
    show: boolean;
    modal_type: ModalType;
}

export interface IGlobalContext {
    /**
     * All tiers available - coming from the backend
     */
    _tiers: ITier[];
    _setTiers: React.Dispatch<React.SetStateAction<ITier[]>>;
    /**
     * The current tier of the logged in user
     */
    _userTier: IUserTier;
    /**
     * Set the current tier of the logged in user with the data from the backend
     */
    _setUserTier: React.Dispatch<React.SetStateAction<IUserTier>>;
    _hasFetchedUserTier: boolean;
    _setHasFetchedUserTier: React.Dispatch<React.SetStateAction<boolean>>;

    /**
     * Toggle the tier drawer
     * todo: specify maybe which drawer this is? there are multiple drawers, right?
     */
    _tierDrawerOpen: boolean;
    /**
     * Set the tier drawer open state
     */
    _setTierDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    _tierUpgradeStage: UpgradeTierStage;
    _setTierUpgradeStage: React.Dispatch<
        React.SetStateAction<UpgradeTierStage>
    >;
    _requiredDCB: number;
    _setRequiredDCB: React.Dispatch<React.SetStateAction<number>>;

    /**
     * Contract Manager - can be used to get any contract and will make sure
     * that the provider being used is always working and the lowest latency
     */
    contract_manager: IContractManager | null;

    /**
     allocation popup in the onboarding flow
     */
    showAllocationPopup: boolean;
    setShowAllocationPopup: React.Dispatch<React.SetStateAction<boolean>>;

    /**
     kyc modal popup in the onboarding flow
     */
    showKycCompleted: boolean;
    setShowKycCompleted: React.Dispatch<React.SetStateAction<boolean>>;

    /**
     * List of projects
     */
    projects: Project[];
    setProjects: React.Dispatch<React.SetStateAction<Project[]>>;

    /**
     * Whether the projects have been loaded
     */
    projects_loaded: boolean;

    /**
     * Load the projects
     */

    loadProjects: () => Promise<void>;

    /**
     * Content sections
     */
    content_sections: Record<string, IContentSection>;

    /**
     * Load content sections
     */
    loadContent: () => Promise<void>;

    /**
     * Whether the content has been loaded
     */
    content_loaded: boolean;
    notification: NotificationBarProps;

    /**
     * Event registration context
     */
    eventRegistrationContext: EventRegistrationContext | undefined;
    setEventRegistrationContext: React.Dispatch<
        EventRegistrationContext | undefined
    >;
    /**
     * Event registration modals
     */
    event_modal_state?: EventModalState;
    setEventModalState?: React.Dispatch<React.SetStateAction<EventModalState>>;
    closeEventModal: () => void;
    user_status: {
        has_verified_identity: boolean;
        has_verified_wallet: boolean;
        has_pending_verification_status: boolean;
        is_wallet_pending: boolean;
        has_failed_verification_status: boolean;
    };
    show_allocation_modal: boolean;
    setShowAllocationModal: React.Dispatch<React.SetStateAction<boolean>>;
    show_buy_modal: boolean;
    setShowBuyModal: (bool: boolean) => void;

    is_eu_country: boolean;

    // if the application has a restriction on EU countires
    has_eu_restriction: boolean;
}

export const GlobalContext = createContext<IGlobalContext>(null);

export const useTiers = () => {
    const [tiers, setTiers] = React.useState<ITier[]>([]);
    const [userTier, setUserTier] = React.useState<IUserTier | null>(null);
    const [tierDrawerOpen, setTierDrawerOpen] = React.useState<boolean>(false);
    const [tier_upgrade_stage, setTierUpgradeStage] =
        React.useState<UpgradeTierStage>(UpgradeTierStage.Tier);
    const [requiredDCB, setRequiredDCB] = React.useState<number>(0);
    const [hasFetchedUserTier, setHasFetchedUserTier] =
        React.useState<boolean>(false);
    const [eventRegistrationContext, setEventRegistrationContext] = useState<
        EventRegistrationContext | undefined
    >();
    const [event_modal_state, setEventModalState] = useState<EventModalState>({
        show: false,
        modal_type: ModalType.Login,
    });

    const [showAllocationPopup, setShowAllocationPopup] =
        React.useState<boolean>(false);
    const [showKycCompleted, setShowKycCompleted] =
        React.useState<boolean>(false);

    const { user } = useAppSelector((state) => state.auth);
    const dispatch = useDispatch();

    const _getTierOfUser = useCallback(async () => {
        const userTier = await getTierOfUser();

        setUserTier(userTier);
        setHasFetchedUserTier(true);
    }, []);

    const _getTiers = useCallback(async () => {
        const tiers = await getTiers();
        setTiers(tiers);
    }, []);

    useEffect(() => {
        if (user) {
            _getTierOfUser();
        } else {
            setUserTier(null);
        }
    }, [user, _getTierOfUser]);

    useEffect(() => {
        // only need timer is status is pending
        if (user?.identity_verified !== IdentityVerifiedStatus.PENDING) return;

        const handler = setTimeout(() => {
            return getAndSetUser(dispatch);
        }, 5000);

        return () => clearTimeout(handler);
    }, [user, getAndSetUser]);

    useEffect(() => {
        _getTiers();
    }, [_getTiers]);

    return {
        _tiers: tiers,
        _setTiers: setTiers,
        _userTier: userTier,
        _setUserTier: setUserTier,
        _tierDrawerOpen: tierDrawerOpen,
        _setTierDrawerOpen: setTierDrawerOpen,
        _tierUpgradeStage: tier_upgrade_stage,
        _setTierUpgradeStage: setTierUpgradeStage,
        _requiredDCB: requiredDCB,
        _setRequiredDCB: setRequiredDCB,
        _hasFetchedUserTier: hasFetchedUserTier,
        _setHasFetchedUserTier: setHasFetchedUserTier,
        showAllocationPopup,
        setShowAllocationPopup,
        showKycCompleted,
        setShowKycCompleted,
        eventRegistrationContext,
        setEventRegistrationContext,
        event_modal_state,
        setEventModalState,
        closeEventModal() {
            setEventModalState((current_state) => ({
                ...current_state,
                show: false,
            }));
        },
    };
};

export const GlobalContextProvider: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const tiers = useTiers();
    const [contract_manager, setContractManager] =
        React.useState<IContractManager | null>(null);

    const [projects, setProjects] = React.useState<Project[]>([]);
    const [content_sections, setContentSections] = React.useState<
        Record<string, IContentSection>
    >({});
    const [projects_loaded, setProjectsLoaded] = React.useState(false);
    const [content_loaded, setContentLoaded] = React.useState(false);

    const [show_allocation_modal, _setShowAllocationModal] =
        React.useState(false);

    const setShowAllocationModal = (bool: boolean) => {
        if (CONTENT.companyName.toLowerCase().includes('decubate')) {
            return _setShowAllocationModal(bool);
        } else {
            return tiers._setTierDrawerOpen(bool);
        }
    };

    const [show_buy_modal, setShowBuyModal] = useState(false);

    const [is_eu_country, setIEUCountry] = useState<boolean>(false);
    const [country, setCountry] = useState<string>();
    const [has_eu_restriction, setHasEURestriction] = useState<boolean>(false);

    const { user } = useAppSelector((state) => state.auth);

    const notification = useNotification();

    useEffect(() => {
        setIEUCountry(EU_COUNTRIES.has(country));
    }, [country]);

    useEffect(() => {
        getHasEURestrictionBool().then((value) => setHasEURestriction(value));
    }, []);

    useEffect(() => {
        async function fetchIPDetails() {
            try {
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                setCountry(data.country_code);
            } catch (error) {
                console.error('Error fetching IP/country:', error);
                setCountry('');
            }
        }

        fetchIPDetails();
    }, []);

    useEffect(() => {
        const initContractManager = async () => {
            const manager = createContractManager();
            await manager.init();
            setContractManager(manager);
        };

        initContractManager();
    }, []);

    // TODO: should I catch the error here?
    const loadProjects = useCallback(async () => {
        const {
            retrieveProjects: { data },
        } = await api_client.query<{
            retrieveProjects: { data: Project[] };
        }>({
            query: PROJECT.GET_LIST,
            variables: { limit: 100 },
            fetchPolicy: 'network-only',
        });

        // add default durations to events that don't have them
        const projects = data.reduce((acc, p) => {
            if (p.events?.length) {
                const project_events = [];
                for (const e of p.events) {
                    const events = [
                        {
                            ...e,
                            event_details: {
                                ...(e.event_details || {}),
                                durations: e.event_details?.durations || [
                                    3600 * 22,
                                    3600,
                                    3600,
                                ],
                            },
                        },
                    ];
                    project_events.push({ ...p, events });
                }
                return [...acc, ...project_events];
            } else {
                // if there are no events, just push the project as it is with an empty events array
                return [...acc, { ...p, events: [] }];
            }
        }, []);

        const projects_with_event_status = await Promise.all(
            projects.map(async (p) => {
                const status = await getEventStatus(
                    p.events[0],
                    p.announced,
                    contract_manager,
                    user?.id,
                );

                return { ...p, event_status: status };
            }),
        );

        setProjects(projects_with_event_status);
        setProjectsLoaded(true);
    }, [contract_manager, user?.id]);

    const loadContent = useCallback(async () => {
        const {
            retrieveHeroSection: hero_section,
            retrievePartnerSection: partner_section,
            retrieveFAQSection: homepage_faqs,
            retrieveIDOSection: ido_section,
            retrieveGetStartedSection: get_started_section,
            retrieveAdditionalInfoSection: additional_info_section,
        } = await api_client.query<{
            retrieveHeroSection: IContentSection;
            retrievePartnerSection: IContentSection;
            retrieveFAQSection: IContentSection;
            retrieveIDOSection: IContentSection;
            retrieveGetStartedSection: IContentSection;
            retrieveAdditionalInfoSection: IContentSection;
        }>({
            query: CONTENT_API.RETRIEVE_CONTENT_SECTIONS,
            variables: {
                hero_section_name: ContentSectionType.HeroSection,
                parter_section_name: ContentSectionType.PartnerSection,
                faq_section_name: ContentSectionType.FAQ,
                ido_section_name: ContentSectionType.ApplyForIDO,
                get_started_section_name: ContentSectionType.GetStartedSection,
                additional_info_section_name:
                    ContentSectionType.AdditionalInfoSection,
            },
            fetchPolicy: 'network-only',
        });

        setContentSections({
            hero_section,
            partner_section,
            homepage_faqs,
            ido_section,
            get_started_section,
            additional_info_section,
        });
        setContentLoaded(true);
    }, []);

    const user_status = useUserStatus();

    const globalContext = {
        ...tiers,
        contract_manager,
        projects,
        setProjects,
        projects_loaded,
        content_sections,
        loadProjects,
        loadContent,
        content_loaded,
        notification,
        user_status,
        show_allocation_modal,
        setShowAllocationModal,
        show_buy_modal,
        setShowBuyModal,
        is_eu_country,
        has_eu_restriction,
    };

    return (
        <GlobalContext.Provider value={globalContext}>
            {children}
        </GlobalContext.Provider>
    );
};

export function useGlobalContext() {
    return React.useContext(GlobalContext);
}
