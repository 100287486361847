import { useEffect, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';

import { getTokenPrice } from '@src/components/Staking/Analytics/util';

export const useTokenPriceWei = (
    symbol: string,
    base_token_decimals: number,
): BigNumber => {
    const [price, setPrice] = useState(BigNumber.from(0));

    useEffect(() => {
        getTokenPrice(symbol)
            .then((price) => {
                const s = price.toFixed(base_token_decimals);
                const parsed = parseUnits(s, base_token_decimals);
                setPrice(parsed);
            })
            .catch((e) => {
                console.error(`Failed to fetch price for ${symbol}`, e);
            });
    }, []);

    return price;
};
