import { gql } from '@apollo/client';

export const CONTENT_API = {
    RETRIEVE_COMPLETED_EVENTS: gql`
        query RetrieveCompletedEvents(
            $event_type: EventType!
            $offset: Int
            $amount: Int
            $sort_key: CompletedEventSortKey
            $sort_order: SortOrder
        ) {
            retrieveCompletedEvents(
                event_type: $event_type
                offset: $offset
                amount: $amount
                sort_key: $sort_key
                sort_order: $sort_order
            ) {
                data {
                    name
                    project_slug
                    network
                    ended_date
                    participants
                    total_raised
                    total_distributed
                    num_whitelisted
                    ath_roi
                    event_price
                    ath
                    fdv
                    ticker
                    token_address
                    token_decimals
                    hardcap
                    project_logo
                    project_id
                }
                total_count
            }
        }
    `,

    RETRIEVE_CONTENT_SECTIONS: gql`
        query RetrieveContentSections(
            $parter_section_name: ContentSectionType!
            $faq_section_name: ContentSectionType!
            $ido_section_name: ContentSectionType!
            $get_started_section_name: ContentSectionType!
            $additional_info_section_name: ContentSectionType!
        ) {
            retrievePartnerSection: retrieveContentSection(
                name: $parter_section_name
            ) {
                title
                name
                images {
                    name
                    url
                    link
                }
            }
            retrieveFAQSection: retrieveContentSection(
                name: $faq_section_name
            ) {
                name
                data {
                    key
                    value
                }
            }
            retrieveIDOSection: retrieveContentSection(
                name: $ido_section_name
            ) {
                name
                title
                subtitle
                images {
                    name
                    url
                }
                buttons {
                    name
                    url
                }
            }
            retrieveGetStartedSection: retrieveContentSection(
                name: $get_started_section_name
            ) {
                name
                images {
                    name
                    url
                    text
                }
                title
                subtitle
            }
            retrieveAdditionalInfoSection: retrieveContentSection(
                name: $additional_info_section_name
            ) {
                name
                images {
                    name
                    url
                    text
                }
            }
        }
    `,

    RETRIEVE_CONTENT_SECTION: gql`
        query RetrieveContentSection($name: ContentSectionType!) {
            retrieveContentSection(name: $name) {
                alignment
                name
                text
                title
                buttons {
                    name
                    opaque
                    text
                    url
                }
                images {
                    name
                    url
                    link
                }
                data {
                    key
                    value
                }
                hidden
            }
        }
    `,

    RETRIEVE_HERO_SECTION: gql`
        query RetrieveHeroSection($name: ContentSectionType!) {
            retrieveHeroSection: retrieveContentSection(name: $name) {
                name
                images {
                    name
                    url
                    link
                    text
                }
                subtitle
                title
                subtitle
                data {
                    key
                    value
                }
            }
        }
    `,
};
