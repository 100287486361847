import { SortOrder } from '@decub8/ui';

import { api_client } from '@src/bootstrap/index';
import { CompletedEventSortKey } from '@src/components/HomepageComponents';
import { CONTENT } from '@src/config';
import { country_code_map } from '@src/constants';
import { CONFIG } from '@src/services/confg';
import { CONTENT_API } from '@src/services/content';
import { PROJECT } from '@src/services/project';
import { ConfigKey, ContentSectionType, EventType } from '@src/ts/constants';
import {
    BackendConfigItem,
    ICompletedEventsResponse,
    IContentSection,
    NotificationData,
} from '@src/ts/interfaces';

export const getNotificationData = async (): Promise<NotificationData> => {
    const { getNotification } = await api_client.query<{
        getNotification: NotificationData;
    }>({
        query: PROJECT.GET_NOTIFICATION_DATA,
        fetchPolicy: 'network-only',
    });

    return getNotification;
};

export const getHasEURestrictionBool = async () => {
    const {
        retrieveConfig: { value },
    } = await api_client.query<{ retrieveConfig: BackendConfigItem }>({
        query: CONFIG.GET_CONFIG_ITEM,
        variables: {
            key: ConfigKey.EuRestricted,
        },
    });

    return value === 'true';
};

export const getCompletedEventsData = async (
    amount: number,
    offset: number,
    type: EventType,
    sort_key: CompletedEventSortKey,
    sort_order: SortOrder,
): Promise<ICompletedEventsResponse> => {
    const { retrieveCompletedEvents: completed_events } =
        await api_client.query<{
            retrieveCompletedEvents: ICompletedEventsResponse;
        }>({
            query: CONTENT_API.RETRIEVE_COMPLETED_EVENTS,
            fetchPolicy: 'network-only',
            variables: {
                offset,
                amount,
                event_type: type,
                sort_key,
                sort_order,
            },
        });

    return completed_events;
};

export const getPlatformImages = async () => {
    const images = await api_client.query({
        query: PROJECT.GET_PLATFORM_IMAGES,
    });
    return images['retrieveImages'];
};

export const getIDOSectionProps = (section: IContentSection) => {
    const button_url = section?.buttons?.find(
        (button) => button.name === 'apply_button',
    )?.url;

    return {
        title: section?.title,
        subtitle: section?.subtitle,
        handleClick: () => {
            window.open(
                button_url || CONTENT.apply_for_ido_link,
                '_blank',
                'norefferer',
            );
        },
        desktop_image: section?.images?.find(
            (image) => image.name === 'desktop',
        )?.url,
        mobile_image: section?.images?.find((image) => image.name === 'mobile')
            ?.url,
    };
};

/**
 * Returns the ISO‑3166‑1 alpha‑2 code for the given country name.
 *
 * @param country The country name (must exactly match one of the keys in the mapping)
 * @returns The ISO‑2 country code as a string or an empty string if not found.
 */
export function getCountryCode(country?: string): string {
    return country_code_map[country || ''] || '';
}

export const getContentSection = async (section: ContentSectionType) => {
    const { retrieveContentSection } = await api_client.query<{
        retrieveContentSection: IContentSection;
    }>({
        query: CONTENT_API.RETRIEVE_CONTENT_SECTION,
        fetchPolicy: 'network-only',
        variables: {
            name: section,
        },
    });
    return retrieveContentSection;
};
