import { Button, Typography } from '@decub8/ui';

export const WhitelistSection: React.FC<{
    terms_url: string;
    saft_url?: string;
    className?: string;
    handleClick?: () => void;
    button_disabled?: boolean;
    button_loading?: boolean;
}> = ({
    terms_url,
    className,
    handleClick,
    saft_url,
    button_disabled,
    button_loading,
}) => {
    return (
        <div className={className}>
            <Button
                disabled={button_disabled}
                loading={button_loading}
                onClick={handleClick}
                className="w-full"
            >
                Whitelist for this event
            </Button>

            <Typography size="sm" variant="secondary" className="mt-3">
                By clicking ‘Whitelist for this event’ you agree to{' '}
                <a
                    className="hover:underline text-accent text-base"
                    href={terms_url}
                    rel="noreferrer"
                    target="_blank"
                >
                    Terms and conditions
                </a>{' '}
                and{' '}
                <a
                    className="hover:underline text-accent text-base"
                    href={saft_url}
                    rel="noreferrer"
                    target="_blank"
                >
                    SAFT
                </a>{' '}
                and fully understand that participation in token sales is
                speculative and comes with financial risk.
            </Typography>
        </div>
    );
};
