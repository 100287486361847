import nacl from 'tweetnacl';
import naclUtil from 'tweetnacl-util';

import { CONTENT } from '@src/config';

export function generateKeyPair(): void {
    // Generate a new Ed25519 key pair
    const keypair = nacl.sign.keyPair();

    // Convert the private and public keys to hex and log them
    console.log(
        'NEW Private Key (Hex):',
        Buffer.from(keypair.secretKey).toString('hex'),
    );
    console.log(
        'NEW Public Key (Hex):',
        Buffer.from(keypair.publicKey).toString('hex'),
    );
}

export const getSignatureAndTs = (): [string, string] => {
    try {
        const ts = Date.now();
        let tsHex = ts.toString(16);
        if (tsHex.length % 2 !== 0) tsHex = '0' + tsHex; // Pad to ensure even length

        const pk = CONTENT.rpc_api_secret;
        if (!pk) {
            return ['', ''];
        }

        const privateKey = new Uint8Array(Buffer.from(pk, 'hex'));
        const encodedTs = new Uint8Array(Buffer.from(tsHex, 'hex')); // Convert to Uint8Array
        const signedTs = nacl.sign.detached(encodedTs, privateKey); // Detached signature
        const b64 = naclUtil.encodeBase64(signedTs);

        return [tsHex, b64];
    } catch (error) {
        console.log('Error signing timestamp:', error);
        return ['', ''];
    }
};
