import React from 'react';
import dayjs from 'dayjs';

import { CONTENT } from '@src/config';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { ProjectEvent as IProjectEvent } from '@src/ts/interfaces';

import { AllocationCardContainer } from '../../AllocationCardContainer';
import { ProjectEvent } from '../';

export const EventContainer: React.FC<{
    project_id: string;
    scrollToLearnToEarn: () => void;
    event: IProjectEvent;
    mobile?: boolean;
}> = ({ event, project_id, scrollToLearnToEarn, mobile = false }) => {
    const { notification } = useGlobalContext();

    const show_allocation_card =
        dayjs().isBefore(
            dayjs(Number(event.start_date) * 1000).subtract(30, 'minute'),
        ) &&
        !!event.contract.abi &&
        !!event.contract;

    // when the buy dcb modal is open make sure the event widget is smaller z index
    const { show_buy_modal } = useGlobalContext();

    return (
        <div
            className={`mb-20 ${
                mobile
                    ? 'w-full lg:hidden mt-5'
                    : 'hidden lg:inline-block lg:w-[380px]'
            }`}
        >
            <div
                className={
                    mobile
                        ? ''
                        : `rounded space-y-5 ${show_buy_modal ? '-z-10' : ''} ${
                              notification?.active ? 'top-[144px]' : 'top-20'
                          }`
                }
            >
                {/* fill in correct props */}
                {show_allocation_card &&
                    CONTENT.companyName.toLowerCase().includes('decubate') && (
                        <AllocationCardContainer className="mb-[24px]" />
                    )}

                <ProjectEvent
                    project_id={project_id}
                    scrollToLearnToEarn={scrollToLearnToEarn}
                    event={event}
                />
            </div>
        </div>
    );
};
