import React from 'react';
import { GetStarted, GetStartedProps } from '@decub8/ui';
import { NextRouter, useRouter } from 'next/router';

import { useAppSelector } from '@src/hooks/index';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { IContentSection, User } from '@src/ts/interfaces';

import { steps } from '../constants';
import { header_height, scrollIntoView } from '../utils';

const getHandleClick = (
    name: string,
    router: NextRouter,
    events_ref: React.RefObject<HTMLDivElement>,
    user: User,
    has_verified_identity: boolean,
    has_pending_verification_status: boolean,
    has_verified_wallet: boolean,
    is_wallet_pending: boolean,
) => {
    switch (name) {
        case 'create_account':
            return () =>
                user ? router.push('/account') : router.push('/login');
        case 'verify_id':
            return () =>
                user
                    ? has_verified_identity || has_pending_verification_status
                        ? router.push('/account')
                        : router.push('/verify/identity')
                    : router.push('/login');
        case 'verify_wallet':
            return () =>
                user
                    ? has_verified_wallet || is_wallet_pending
                        ? router.push('/account')
                        : router.push('/verify/wallet')
                    : router.push('/login');
        case 'invest':
            return () => scrollIntoView(events_ref, header_height);
        default:
            return () => {};
    }
};

export const GetStartedSection: React.FC<{
    section: IContentSection;
    className?: string;
    events_ref: React.RefObject<HTMLDivElement>;
}> = ({ section, className, events_ref }) => {
    const router = useRouter();
    const user = useAppSelector((state) => state?.auth.user);

    const {
        user_status: {
            has_verified_identity,
            has_pending_verification_status,
            has_verified_wallet,
            is_wallet_pending,
        },
    } = useGlobalContext();

    const data = steps.map(({ name, title }) => {
        const image_item = section.images.find((image) => image.name === name);

        return {
            title,
            subtitle: image_item?.text || '',
            logo: image_item?.url || '',
            handleClick: getHandleClick(
                name,
                router,
                events_ref,
                user,
                has_verified_identity,
                has_pending_verification_status,
                has_verified_wallet,
                is_wallet_pending,
            ),
        };
    });

    let arrow_right: undefined | string = undefined;
    let arrow_down: undefined | string = undefined;

    if (section?.images?.length > 0) {
        arrow_right = section.images.find(
            (image) => image.name === 'arrow_right',
        )?.url;
        arrow_down = section.images.find(
            (image) => image.name === 'arrow_down',
        )?.url;
    }

    const props: GetStartedProps = {
        title: section?.title,
        subtitle: section?.subtitle,
        data,
        custom_arrow_right: arrow_right,
        custom_arrow_down: arrow_down,
    };

    return <GetStarted {...props} className={className} />;
};
